import { useState } from 'react';
import LinkButton from '../LinkButton';
import YodleeLink from '../YodleeLink';
import { createYodleeToken } from './api';

interface Props {
  onComplete: () => void;
}

export default function YodleeLinkButton({ onComplete }: Props) {
  const [linkToken, setLinkToken] = useState<string>();

  function handleClick() {
    createYodleeToken().then(({ accessToken }: { accessToken: string }) => {
      setLinkToken(accessToken);
    });
  }

  return (
    <>
      {linkToken && (
        <YodleeLink linkToken={linkToken} onComplete={onComplete} />
      )}
      <LinkButton onClick={handleClick} />
    </>
  );
}
