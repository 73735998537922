import { useState } from 'react';
import { AGGREGATOR } from '../../common/config';
import { Aggregators } from '../../common/config';
import PlaidLinkButton from '../PlaidLinkButton';
import YodleeLinkButton from '../YodleeLinkButton';

export default function AccountLinkButton() {
  const [linkComplete, setLinkComplete] = useState<boolean>(false);

  const onComplete = () => {
    setLinkComplete(true);
  };

  if (linkComplete) {
    console.log('complete');
  }
  let LinkButton = null;

  switch (+AGGREGATOR) {
    case Aggregators.Plaid:
      LinkButton = <PlaidLinkButton onComplete={onComplete} />;
      break;
    case Aggregators.Yodlee:
      LinkButton = <YodleeLinkButton onComplete={onComplete} />;
      break;
    default:
      LinkButton = null;
  }

  return LinkButton;
}
