import React, { useEffect } from 'react';
import {
  usePlaidLink,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkError,
  PlaidLinkOptionsWithLinkToken,
  PlaidLinkOnEventMetadata,
  PlaidLinkStableEvent,
} from 'react-plaid-link';

import { exchangePublicToken } from './api';

interface Props {
  isOauth?: boolean;
  itemId?: number | null;
  children?: React.ReactNode;
  onComplete: () => void;
  linkToken: string;
}

// Uses the usePlaidLink hook to manage the Plaid Link creation.
// See https://github.com/plaid/react-plaid-link for full usage instructions.
// The link token passed to usePlaidLink cannot be null.

export default function PlaidLink({
  isOauth,
  itemId,
  onComplete,
  linkToken,
}: Props) {
  const onSuccess = (
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) => {
    // TODO:// save meta data

    if (itemId != null) {
      // update mode: no need to exchange public token, but
      // still need to update item via API
      // TODO: // update item via API
      // delete link token
    } else {
      exchangePublicToken(publicToken).then(() => {
        onComplete();
      });
    }
    // reset any errors states & delete link token

    onComplete();
  };

  const onExit = (
    error: PlaidLinkError | null,
    metadata: PlaidLinkOnExitMetadata
  ) => {
    if (error != null) {
      // set global error modal?
      // setError(error.display_message || error.error_message);
    }
    // to handle other error codes, see https://plaid.com/docs/errors/
  };

  const onEvent = (
    eventName: PlaidLinkStableEvent | string,
    metadata: PlaidLinkOnEventMetadata
  ) => {
    // handle errors in the event end-user does not exit with onExit function error enabled.
    if (eventName === 'ERROR' && metadata.error_code != null) {
      // setError(metadata.error_code, ' ');
    }
  };

  const config: PlaidLinkOptionsWithLinkToken = {
    onSuccess,
    onExit,
    onEvent,
    token: linkToken,
  };

  if (isOauth) {
    config.receivedRedirectUri = window.location.href; // add additional receivedRedirectUri config when handling an OAuth reidrect
  }

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    // initiallizes Link automatically
    if (isOauth && ready) {
      open();
    } else if (ready) {
      // regular, non-OAuth case:
      // set link token, userId and itemId in local storage for use if needed later by OAuth
      localStorage.setItem(
        'oauthConfig',
        JSON.stringify({
          itemId: itemId,
          token: linkToken,
        })
      );
      open();
    }
  }, [ready, open, isOauth, itemId, linkToken]);

  return <></>;
}
