import { useEffect, useState } from 'react';
export type UserExperienceFlowType =
  | 'Aggregation'
  | 'Verification'
  | 'Aggregation plus Verification';

export type TokenEnumType = 'AccessToken' | 'JwtToken';

export interface TokenType {
  tokenType: TokenEnumType;
  tokenValue: string;
}

export interface YodleeHookPropsType {
  containerId: string;
  token?: TokenType;
  onSuccess: (args: any) => void;
  onError: (args: any) => void;
  onExit: (args: any) => void;
  onEvent?: (args: any) => void;
}

export interface YodleeHookReturnType {
  init: (token?: TokenType) => void;
  data: any;
  error: any;
  ready: boolean;
  active: boolean;
}

export interface YodleeHookType {
  (props: YodleeHookPropsType): YodleeHookReturnType;
}

declare global {
  interface Window {
    fastlink: any;
  }
}

export default function useYodlee({
  containerId,
  onSuccess,
  onError,
  onExit,
  onEvent,
}: YodleeHookPropsType) {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    let script: HTMLScriptElement;

    script = document.createElement('script');

    script.id = 'yodlee-fastlink-script';
    script.src = 'https://cdn.yodlee.com/fastlink/v4/initialize.js';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      console.log('onload complete');
      setReady(true);
    };
    script.onerror = () => document.body.appendChild(script);

    document.body.appendChild(script);
    return () => {
      window.fastlink?.close();
      document.body.removeChild(script);
    };
  }, []);

  const open = (token: string) => {
    console.log('opening with token', token);
    window.fastlink?.open(
      {
        fastLinkURL:
          'https://fl4.sandbox.yodlee.com/authenticate/restserver/fastlink',
        params: {
          configName: 'Verification',
        },
        accessToken: `Bearer ${token}`,
        onSuccess: (customerData: any) => {
          onSuccess(customerData);
        },
        onError: (fastLinkError: any) => {
          onError(fastLinkError);
        },
        onClose: (args: any) => onExit(args),
        onEvent: (event: any) => {
          console.log('event', event);
          onEvent && onEvent(event);
        },
      },
      containerId
    );
  };

  return {
    open,
    ready,
  };
}
