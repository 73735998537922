import styles from './styles.module.css';

export interface ButtonProps {
  children?: any;
  onClick?: () => void;
  isDisabled?: boolean;
  [x: string]: any;
}

function Button(props: ButtonProps) {
  const { onClick, isDisabled, children, ...rest } = props;
  return (
    <button
      className={styles.button}
      type="button"
      onClick={onClick}
      disabled={isDisabled}
      {...rest}
    >
      {children}
    </button>
  );
}

export default Button;
