import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import UserContextProvider from './context/UserContextProvider';
import AccountsPage from './routes/AccountPage';
import ErrorBoundary from './components/ErrorBoundary';
import { ENV, GOOGLE_ANALYTICS_KEY } from './common/config';
import { initialize } from './lib/analytics/google';

// Load google before anything else
if (ENV === 'production' && GOOGLE_ANALYTICS_KEY) {
  initialize(GOOGLE_ANALYTICS_KEY);
}

const AppLayout = lazy(() => import('./routes/AppLayout'));
const LoginPage = lazy(() => import('./routes/LoginPage'));
const HomePage = lazy(() => import('./routes/HomePage'));
const PollPage = lazy(() => import('./routes/PollPage'));
const SignupPage = lazy(() => import('./routes/SignupPage'));
const LogoutPage = lazy(() => import('./routes/LogoutPage'));
const PublicLayout = lazy(() => import('./routes/PublicLayout'));

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <UserContextProvider>
        <BrowserRouter>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<PublicLayout />}>
                <Route path="login" element={<LoginPage />} />
                <Route path="signup/*" element={<SignupPage />} />
                <Route path="logout" element={<LogoutPage />} />
                <Route path="/" element={<HomePage />} />
              </Route>
              <Route path="/" element={<AppLayout />}>
                <Route path="close" element={<PollPage />} />
                <Route path="accounts" element={<AccountsPage />} />
                <Route path="accounts/redirect" element={<AccountsPage />} />
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>
      </UserContextProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
