import { useState } from 'react';
import LinkButton from '../LinkButton';
import PlaidLink from '../PlaidLink';
import { createPlaidToken } from './api';

interface Props {
  onComplete: () => void;
}

export default function PlaidLinkButton({ onComplete }: Props) {
  const [linkToken, setLinkToken] = useState<string>();

  function handleClick() {
    createPlaidToken().then(({ linkToken }: { linkToken: string }) => {
      setLinkToken(linkToken);
    });
  }

  return (
    <>
      {linkToken && <PlaidLink linkToken={linkToken} onComplete={onComplete} />}
      <LinkButton onClick={handleClick} />
    </>
  );
}
