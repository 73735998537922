import AccountLinkButton from '../../components/AccountLinkButton';
import styles from './styles.module.css';

export default function AccountsPage() {
  return (
    <div id="accounts-page" className={styles.accountsPage}>
      <AccountLinkButton />
    </div>
  );
}
