import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import useYodlee from '../../hooks/useYodlee';
import { verifyAccount } from './api';

interface Props {
  children?: React.ReactNode;
  onComplete: () => void;
  linkToken: string;
}

// Uses the useYodlee hook to manage the Fast Link creation.
// See https://developer.yodlee.com/docs/fastlink/4.0/getting-started

export default function YodleeLink({ onComplete, linkToken }: Props) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);

  const onSuccess = (results: any) => {
    // TODO:// save meta data
    const { providerAccountId } = results;

    verifyAccount({ providerAccountId }).then((accounts) => {
      console.log('accounts', accounts);
      onComplete();
    });
  };

  const onExit = () => {
    setIsModalOpen(false);
  };

  const onError = (error: any) => {
    console.log('err', error);
    // setIsModalOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const { open, ready } = useYodlee({
    containerId: 'yodlee-modal-container',
    onSuccess,
    onExit,
    onError,
  });

  useEffect(() => {
    console.log('ready', ready);
    console.log('open', open);
    if (ready) {
      open(linkToken);
    }
  }, [ready, open, linkToken]);

  const modalStyles = {
    content: {
      maxWidth: '750px',
      margin: '0 auto',
    },
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      contentLabel="Yodlee Modal"
      style={modalStyles}
      ariaHideApp={false}
    >
      <div id="yodlee-modal-container" />
    </Modal>
  );
}
