import { API_URL } from './config';
function send(path: string, params: any) {
  const reqParams = Object.assign(
    {},
    {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    params
  );

  return fetch(API_URL + path, reqParams).then((response) => {
    if (!response.ok) {
      return response.json().then((errResponse) => {
        if (errResponse && errResponse.message) {
          throw new Error(errResponse.message);
        } else {
          throw new Error('Sorry, something went wrong');
        }
      });
    }

    return response.json();
  });
}

export function post(path: string, data: any) {
  return send(path, {
    method: 'POST',
    body: JSON.stringify(data),
  }).catch((err) => {
    console.log('err1', err);
  });
}

export function patch(path: string, data: any) {
  return send(path, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
}

export function del(path: string) {
  return send(path, {
    method: 'DELETE',
  });
}

export function get(path: string, headers?: any) {
  return send(path, {
    method: 'GET',
    headers,
  });
}

export function getExternal(url: string, headers?: any) {
  return fetch(url)
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log('err', err);
    });
}

export function fetchUser() {
  return get('/users/me');
}
