import { useEffect, useState } from 'react';
import { fetchUser } from '../common/api';
import UserContext, { User } from './UserContext';

export default function UserContextProvider({ children }: { children: any }) {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchUser()
      .then((user: User) => {
        if (user) {
          addUser(user);
        }

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  function removeUser() {
    setUser(null);
  }

  function addUser(user: User) {
    setUser(user);
  }

  return (
    <UserContext.Provider value={{ user, removeUser, addUser, isLoading }}>
      {children}
    </UserContext.Provider>
  );
}
