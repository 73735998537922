export function initialize(key: string) {
  const $tagScript = document.createElement('script');
  $tagScript.setAttribute(
    'src',
    `https://www.googletagmanager.com/gtag/js?id=${key}`
  );

  $tagScript.async = true;
  document.head.appendChild($tagScript);

  const $dataLayerScript = document.createElement('script');
  const scriptData = document.createTextNode(
    `window.dataLayer = window.dataLayer || []; \n function gtag(){dataLayer.push(arguments);} \n gtag('js', new Date()); \n gtag('config', '${key}');`
  );
  $dataLayerScript.appendChild(scriptData);
  document.head.appendChild($dataLayerScript);
}
