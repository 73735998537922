import React from 'react';

export interface User {
  id: string;
  name: string;
  email: string;
}

interface UserContext {
  user: User | null;
  addUser: (user: User) => void;
  removeUser: () => void;
  isLoading: boolean;
}

export default React.createContext<UserContext>({
  user: null,
  addUser: () => {},
  removeUser: () => {},
  isLoading: true,
});
