export enum Aggregators {
  Plaid,
  MX,
  Yodlee,
}

export const API_URL = process.env.REACT_APP_API_URL;
export const ENV = process.env.REACT_APP_ENV;
export const POLYGON_API_KEY = process.env.REACT_APP_POLYGON_API_KEY;
export const IEX_API_KEY = process.env.REACT_APP_IEX_API_KEY;
export const AGGREGATOR = Aggregators.Yodlee;
export const GOOGLE_ANALYTICS_KEY = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;
